.App {
  font-family: sans-serif;
  text-align: center;
}
.spinner {
  z-index: "99";
  text-align: center;
  color: #5968bf;
}
.fullPageSpinner {
  z-index: "99";
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #5968bf;
}
.scrollHeight {
  height: 300px;
}
.manageShipping > .Polaris-Layout > .Polaris-Layout__Section {
  margin-top: 0px;
}
.manageShipping > .Polaris-Layout > .Polaris-Layout__Section:nth-child(2) > .Polaris-Banner {
  margin-top: 15px;
}
.sheetBody {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sheetSection {
  align-items: center;
  border-bottom: 1px solid "#DFE3E8";
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
  width: 100%;
}
.sheetScrollable {
  padding: 1.6rem;
  height: 100%;
}
.checkBoxLabel {
  padding-top: 4px;
}
.formWidth125px {
  width: 125px;
}
.redColor {
  color: #ff0000;
}

.langSelector {
  margin-right: 8px;
}
.suffixColor {
  color: black !important;
}
.totalCount {
  font-weight: 300 !important;
  margin: 5px;
}

/* package-slip page css */
.Polaris-Page__OrderSlip {
  display: flex;
  justify-content: center;
  padding: 15px;
}
.order-slip-box {
  max-width: 800px;
  width: 600px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #000000;
  background: #ffffff;
}
.order-slip-box table {
  table-layout: fixed;
}
.order-slip-box table tbody {
  width: 100%;
  line-height: inherit;
  text-align: left;
}
.order-slip-box table tbody tr {
  background-color: #ffffff;
}
.order-slip-box table td {
  padding: 5px;
  vertical-align: top;
  word-wrap: break-word;
}

.order-slip-box table tr td:nth-child(2),
td:nth-child(3),
td:nth-child(4),
td:nth-child(5) {
  text-align: right;
}

.order-slip-box table tr.top table td {
  padding-bottom: 20px;
}

.order-slip-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
  background-color: #ffffff;
}

.order-slip-box table tr.information table td {
  padding-bottom: 40px;
  background-color: #ffffff;
}

.order-slip-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}

.order-slip-box table tr.details td {
  padding-bottom: 20px;
}

.order-slip-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.order-slip-box table tr.item.last td {
  border-bottom: none;
}

@media only screen and (max-width: 600px) {
  .order-slip-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .order-slip-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  #divToPrint,
  #divToPrint * {
    visibility: visible;
  }
  #divToPrint {
    position: relative;
    left: 0;
    top: 0;
  }
}

@page {
  size: auto;
  margin: 0mm;
}
.invoice-box {
  max-width: 800px;
  width: 600px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #000000;
}
table {
  width: 100%;
}
.invoice-box table tbody {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}
.wrapper {
  display: flex;
  justify-content: center;
}
.top {
  background: white !important;
}
tr.information > td > table > tbody > tr > td:nth-child(even) {
  background: white;
}
tr.information > td > table > tbody > tr > td:nth-child(odd) {
  background: white;
}
tr.item:nth-child(even) {
  background: white;
}

.brandLogo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 16px;
  max-height: 42px;
  max-width: 200px;
}
.brandTitle {
  margin-top: 10px;
  margin-left: 12px;
  display: block;
  white-space: nowrap;
}

.fileInput {
  width: 100%;
  height: 36px;
  padding: 6px 12px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.error .fileInput {
  border: 1px solid red;
}

/* page based css */

.activity_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.activity_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.activity_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.invitationList_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.invitationList_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.invitationList_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.manageShipping_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.manageShipping_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.manageShipping_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.automaticPayoutsList_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.automaticPayoutsList_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}

.bulkActivity_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.bulkActivity_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.bulkActivity_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}

.viewActivity_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.viewActivity_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.viewActivity_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}

.domainList_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.domainList_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.domainList_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.payment_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}
.ordersList_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}

.heading {
  font-size: 30px;
  font-weight: bold;
  color: #686a74;
  word-wrap: break-all;
  padding: 10px;
  padding-left: 23px;
  margin: 5px;
  flex-grow: 1;
}
.Profile-details {
  font-size: 18px;
  font-weight: bold;
  color: #686a74;
  padding: 5px;
  flex-grow: 1;
  padding-left: 30px;
  }
  .content-text {
    padding-left: 30px;
  }
  .content-text-length {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  
  }  
  div.content-text-length:hover {
    overflow: visible;
  }

  .content-text-wrap 
  {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    display: -moz-box;
    -webkit-line-clamp: 1;
    -moz-line-clamp:1;
    -webkit-box-orient: vertical;
    -moz-box-orient :vertical;
  }

  div.content-text-wrap:hover {
    overflow: visible;
    -webkit-line-clamp: 20;
    -moz-line-clamp:20;
  }
  
  .noData {
    display: flex;
    justify-content: center;
    font-size: large;
  }
  .vendorNoImage {
    height: 200px;
    width: 200px;
  }
  .vendorImage {
    height: auto;
    width: 200px;
    margin-top: 17px;;
  }
  .ellipsis {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .toast .Polaris-Frame {
    display: none;
  }
  .sheetSection {
    border-bottom: 1px solid;
    border-color: lightgrey;
  }

  .Polaris-MediaCard__PrimaryAction .Polaris-Button {
    background: linear-gradient(180deg,#6371c7,#5563c1);
    color: whitesmoke;
  }
  .variantEllipsis {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  };

/* start: timeline */
.timelineWrapper {
  position: relative;
  font-size: 13px;
}

.product_version {
  margin-left: 72px;
}

.activity {
  padding-left: 15px;
  overflow: hidden;
}

.timelineWrapper ul li {
  margin-bottom: 0;
}

.timeline {
  margin: 20px 0px 20px 0px;
  font-size: 13px;
  position: relative;
}

.timeline:before {
  content: "";
  position: absolute;
  height: 135%;
  width: 0px;
  margin-top: 2px;
  left: 7px;
  top: 0;
  border: 1px dotted #c2c2c2;
}

.timeline:last-of-type:not(:only-of-type) {
  display: inline;
}

.timeline > ul {
  list-style: none;
}

.timeline .Polaris-List {
  padding-left: 3rem;
}

.timeline:last-child:before {
  content: "";
}

.timelineLink {
  position: absolute;
  right: 20px;
}
.versionList {
  font-size: 13px;
}
.timelineLink button span {
  text-decoration: none;
}
.timelineLink button span:hover {
  text-decoration: underline;
}

.title-list:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #8ac56c;
  border-radius: 100%;
  left: 0;
  z-index: 99;
  box-sizing: content-box;
  margin-top: 2px;
}

.title-list.other:before {
  background-color: #c2c2c2;
}
.more-link {
  display: inline-block;
  padding-left: 4px;
}

.timeline-wrapper ul li {
  margin-bottom: 0;
}

.floatRight {
  float: right;
}

.dashboard {
  position: relative;
  padding: 11px;
}

.check-box { 
  padding-left: 22px; 
}

.bulk-view-ellipsis {
  width: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bulk-view-ellipsis:hover {
  text-overflow: unset;
  width: auto;
  white-space: unset;
  overflow: unset;
}

.wrap_word {
  width: 190px;
}

/* end */

/* manage customer button */
.manageCustomerButton .Polaris-PageActions {
  margin: unset;
  border-top: unset;
  padding: unset;
}
.Polaris-TopBar__ContextControl{
  display: block;
}
/* end */
